<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <b>首页</b>
        </el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>美食管理</el-breadcrumb-item>
        <el-breadcrumb-item>店铺</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input v-model="filters.name" placeholder="请输入名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="reqGetMerchantListPage" icon="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="plus" v-if="permissionButton.add" @click="$router.push({path:'/merchant/add'})">新增</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table :data="merchant" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="merchantName" label="名称" min-width="180" align="center"></el-table-column>
        <el-table-column prop="merchantLocation" label="位置" min-width="400"></el-table-column>
        <el-table-column prop="merchantPhoto" label="列表缩略图片" width="130" align="center">
          <template slot-scope="scope" >
            <img style="width: 89px; height: 50px" :src="downloadFileURL+scope.row.merchantPhoto+'/'"/>
          </template>
        </el-table-column>
        <el-table-column prop="merchantPhone" label="电话" width="120" align="center"></el-table-column>
        <el-table-column prop="merchantLevel" label="推荐指数" width="120" align="center" sortable>
          <template slot-scope="{row}">{{ row.merchantLevel==='1'?'一星推荐': row.merchantLevel==='2'?'二星推荐': row.merchantLevel==='3'?'三星推荐': row.merchantLevel==='4'?'四星推荐': row.merchantLevel==='5'?'五星推荐':''}}</template>
        </el-table-column>

        <el-table-column prop="merchantTop" label="置顶" width="80" align="center" sortable>
          <template slot-scope="scope">
            <span v-bind:class="{temporary: (scope.row.merchantTop)==0}">{{scope.row.merchantTop==0?'是':'否'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="merchantRecommend" label="推荐" width="80" align="center" sortable>
          <template slot-scope="scope">
            <span v-bind:class="{temporary: (scope.row.merchantRecommend)==0}">{{scope.row.merchantRecommend==0?'是':'否'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="merchantReadNumber" label="阅读次数" width="80" align="center"></el-table-column>
        <el-table-column prop="star" label="点赞人数" width="80" align="center">
          <template slot-scope="{row}">{{ row.star || '0' }}</template>
        </el-table-column>
        <el-table-column prop="merchantStatus" label="状态" width="80" align="center" sortable>
          <template slot-scope="scope">
            <span v-bind:class="{temporary: (scope.row.merchantStatus)==1}">{{scope.row.merchantStatus==0?'已发布':'草稿'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="merchantPublisher" label="发布人" width="100" align="center"></el-table-column>

        <el-table-column prop="merchantUpdateTime" label="修改时间" width="160" align="center" sortable :formatter="formatUpdateTime">
          <template slot-scope="{row}">{{ row.merchantUpdateTime || '暂无更改' }}</template>
        </el-table-column>
        <el-table-column prop="merchantCreateTime" label="创建时间" width="160" align="center" sortable :formatter="formatCreateTime"></el-table-column>

        <el-table-column label="操作" width="220" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" v-if="permissionButton.view" @click="$router.push({path:'/merchant/view',query:{merchantId:scope.row.merchantId}})">查看</el-button>
            <el-button type="primary" size="small" v-if="permissionButton.edit" @click="$router.push({path:'/merchant/edit',query:{merchantId:scope.row.merchantId}})">编辑</el-button>
            <el-button type="danger" @click="reqDeleteMerchant(scope.$index,scope.row)" size="small" v-if="permissionButton.del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-col :span="24" class="table-footer">
        <el-button type="danger" size="small" @click="reqBatchDeleteMerchant" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10,15,20,25,50]" :page-size="pageSize" layout=" total, sizes, prev, pager, next, jumper" :total="total" style="float:right;"></el-pagination>
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
import {reqGetDictionaryListByCode,downloadFileURL,} from "../../../api/system-api";
import {reqGetMerchantListPage,reqDeleteMerchant,reqBatchDeleteMerchant,} from "../../../api/merchant-api";
import permission from "../../../common/permission";
import util from "../../../common/util";

export default {
  data() {
    return {
      emptyText: "暂无数据",
      downloadFileURL: downloadFileURL,
      merchant: [],
      total: 0,
      pageNum: 1,
      pageSize: 15,
      listLoading: false,
      sels: [],
      permissionButton: new permission.PermissionButton(),
      filters: {
        name: "",
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;
      this.currentPage = val;
      this.reqGetMerchantListPage();
    },
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.reqGetMerchantListPage();
    },

    goReset() {
      this.filters.name = "";
      this.pageNum = 1;
      this.reqGetMerchantListPage();
    },
    reqGetMerchantListPage() {
      let para = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        merchantName: this.filters.name,
      };
      this.listLoading = true;
      reqGetMerchantListPage(para).then((res) => {
        this.total = res.data.data.total;
        this.merchant = res.data.data.records;
        this.listLoading = false;
      });
    },
    selsChange: function (sels) {
      this.sels = sels;
    },
    formatUpdateTime: function (row) {
      if (row.merchantUpdateTime) {
        return util.formatDate.format(
          new Date(row.merchantUpdateTime),
          "yyyy-MM-dd hh:mm:ss"
        );
      }
    },
    formatCreateTime: function (row) {
      if (row.merchantCreateTime) {
        return util.formatDate.format(
          new Date(row.merchantCreateTime),
          "yyyy-MM-dd hh:mm:ss"
        );
      }
    },
    getDictionarys: function () {
      var that = this;
      reqGetDictionaryListByCode({ code: "columns_type" }).then((res) => {
        that.columnsTypeArray = res.data.data;
      });
    },

    // 删除
    reqDeleteMerchant: function (index, row) {
      this.$confirm("确认删除该记录吗?", "提示", { type: "warning" })
        .then(() => {
          this.listLoading = true;
          let para = { merchantId: row.merchantId };
          reqDeleteMerchant(para).then((res) => {
            if (res.data.code === 1) {
              this.listLoading = false;
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.reqGetMerchantListPage();
            } else {
              this.listLoading = false;
              this.$message({
                message: "删除失败",
                type: "error",
              });
              this.reqGetMerchantListPage();
            }
          });
        })
        .catch(() => {});
    },
    // 批量删除
    reqBatchDeleteMerchant: function () {
      var merchantIds = this.sels.map((item) => item.merchantId).toString();
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          let para = { merchantIds: merchantIds };
          reqBatchDeleteMerchant(para).then((res) => {
            if (res.data.code === 1) {
              this.listLoading = false;
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.reqGetMerchantListPage();
            } else {
              this.listLoading = false;
              this.$message({
                message: "删除失败",
                type: "error",
              });
              this.reqGetMerchantListPage();
            }
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.reqGetMerchantListPage();
    var permissionId = sessionStorage.getItem("permissionId");
    permission.getPermissionButton(permissionId, this.permissionButton);
  },
};
</script>
